import ThumbnailList from "@/components/ThumbnailList.vue";
import PassRequistion from "./PassRequisiton.vue"
import RejectRequisiton from "./RejectRequisiton.vue"
import Phones from "@/components/Phones.vue";

export default {
    name: 'AfterDetail',
    components: { ThumbnailList, PassRequistion, RejectRequisiton, Phones },

    data () {
        return {
            id: undefined, // 商品id，新增商品时无
            loading: false, // 请求状态: 获取
            posting: false, // 请求状态: 保存
            auth: {},       // 用户权限，见created

            orderGoodsList: [],
            orderAfterSalesMap: {},
            showPass: false,
            showReject: false,
            phoneList: [],

        }
    },

    computed: {},

    methods: {
        //查看电话号码

        handlePhoneParam(row) {
            console.log(row);
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/order/queryAddressPhone",
                params: {
                    orderId: row.orderId
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data) {
                            if (res.data[key]) {
                                _phoneList.push(res.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        async fetchData(id) {
            id = id || this.id;
            if (!id) return;
            this.loading = true;

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "api/orderAfterSales/queryOrderAfterSalesInfo",
                    data: { id }
                });
                if (res.code !== 2000 && res.code !== 1003) throw res;
                this.loading = false;



                const {orderGoodsList, orderAfterSalesMap} = res.data;

                if (orderAfterSalesMap.file) {
                    orderAfterSalesMap.file = orderAfterSalesMap.file.split(",").map(path => (this.$baseURL + path));
                }
                else orderAfterSalesMap.file = [];

                if (orderAfterSalesMap.orderGoodsIds) {
                    orderAfterSalesMap.orderGoodsIds = orderAfterSalesMap.orderGoodsIds.split(",").map(Number);
                }
                else orderAfterSalesMap.orderGoodsIds = [];

                let totalPriceOfApply = 0;
                this.orderGoodsList     = orderGoodsList.map(item => {
                    item.containedInApply = orderAfterSalesMap.orderGoodsIds.includes(item.id);
                    if (item.containedInApply) totalPriceOfApply += item.sale_price;
                    return item;
                });

                orderAfterSalesMap.totalPriceOfApply = totalPriceOfApply;
                this.orderAfterSalesMap = orderAfterSalesMap;

                // this.orderAfterSalesMap.type = 2
                // this.orderAfterSalesMap.typeName = "退部分积分"

            } catch (reason) {
                this.loading = false;
                console.warn(reason);
                this.$message({
                    message: "获取售后信息失败，请稍候再试",
                    type: "warning"
                })
                this.navigateBack();
            }
        },

        // 点击保存
        async handleSubmitBtn () {
            try {
                await this.validateStep();

                this.posting = true;
                const pb = this.formatPostBody();
                const res = await this.$oxios({
                    url: "api/goods/saveGoods",
                    method: "post",
                    data: {
                        goodsdata: pb
                    }
                });

                if (res.code === 2000) {
                    this.posting = false;
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    if (this.isAddPage) this.$router.back();
                    
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }

            } catch (reason) {
                this.$message({
                    message: reason.msg || "请根据提示完善商品信息",
                    type: "error"
                });
                console.warn("不能保存数据". reason);
                this.posting = false;
            }
        },

        async handlePassBtn () {
            let fine = true;
            // const {money, totalPriceOfApply} = this.orderAfterSalesMap;
            // if (money != totalPriceOfApply) {
            //     try {
            //         await this.$confirm("申请退单金额与实际退单金额不一致，确定要退单吗？", "温馨提示")
            //     } catch (error) {
            //         fine = false;
            //     }

            // }
            fine && (this.showPass = true);
        },
        handleRejectBtn () {
            this.showReject = true
        },

        navigateBack () {
            this.$router.back();
        }
    },

    created () {
        const id = Number(this.$route.params.id);
        if (id) {
            this.id = id;
            this.fetchData(id);
        }
        this.auth.save = this.$hasAuthFor("api/orderAfterSales/updateOrderAfterSalesState");
        this.auth.seePhone = this.$hasAuthFor("api/order/queryAddressPhone");
    }
}