<!-- 同意退单 -->
<template>
    <el-dialog class="pass-requisition"
        :visible="show"
        @close="handleClose"
        title="处理售后"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="处理方式">{{replaceItem.typeName}}</el-form-item>
            <el-form-item v-show="replaceItem.type==1" label="退单总和">{{ replaceItem.money }}积分</el-form-item>
            <el-form-item v-show="replaceItem.type==2" label="退部分积分" prop="outMoney">
                <el-input v-model="PB.outMoney"
                    maxlength="200"
                    class="width3"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="200"
                    class="width3"></el-input>
            </el-form-item>

        </el-form>
        <div slot="footer">
            <el-button v-show="replaceItem.type==1" @click="handleSave(0)" type="primary">仅退单不退劵</el-button>
            <el-button v-show="replaceItem.type==1" @click="handleSave(1)" type="primary">退单退所有劵</el-button>
            <el-button v-show="replaceItem.type==2" @click="handleSave()" type="primary">确定退积分</el-button>
            <el-button @click="handleClose">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'PassRequisiton',
    props: [ "show", "item", ],

    data () {
       
        return {
            PB: {
                remark: "",
                outMoney:'',
            },
            posting:false,
        }
    },

    computed: {
        'PB.outMoney'(){
            return this.item.money
        },
        
        replaceItem () {
            return this.item || {}
        },
        rules(){
             if(this.item.type==1){
                return  {
                    remark: { required: true, message: "请输入备注" },
                }
            }else if(this.item.type==2){
                return  {
                    remark: { required: true, message: "请输入备注" },
                    outMoney: [
                        { required: true, trigger: "blur", validator: this.validUsername},
            
                    ]

                }
            }else{
                return {}
            }

        },
    },

    watch: {
        show (level, old) {
            console.log(this.item);
            this.PB.outMoney = this.item.money
        }
    },

    methods: {
        validUsername1 (rule, value, callback){
            let maxValue = this.replaceItem.allMoney
            // ([0-9][0-9]{0,1}|${maxValue})$
            const regexPattern  = `([0-9][0-9]{0,1}|${maxValue})$`
            const regex = new RegExp(regexPattern);
            if (value === '') {
                callback(new Error('请输入退部分积分'))
            } else if (!regex.test(value)) {
                callback(new Error(`只能输入整数，不能大于退单订单支付的总和(`+this.replaceItem.allMoney+')'))
            } else {
                callback()
            }
        },
        validUsername (rule, value, callback){

            let _value= value+''
            if (_value === '') {
                callback(new Error('请输入退部分积分'))
            }else if (
                isNaN(Number(_value))
            ) {
                callback(new Error(`只能输入整数，不能大于退单订单支付的总和(`+this.replaceItem.allMoney+')'))
            } else if (
                (_value+'').indexOf('.') != -1
            ) {
                callback(new Error(`只能输入整数，不能大于退单订单支付的总和(`+this.replaceItem.allMoney+')'))
            } else if (
                Number(_value)>this.replaceItem.allMoney
            ) {
                callback(new Error(`只能输入整数，不能大于退单订单支付的总和(`+this.replaceItem.allMoney+')'))
            } else {
                callback()
            }
        },

        async handleSave (type) {
            if(this.posting)return
            this.posting = true
            const loadingRef = this.$loading({
                target: ".pass-requisition .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "api/orderAfterSales/updateOrderAfterSalesState",
                    method: "post",
                    data: {
                        id: this.item.id,
                        type,     // 同意类型 1退券 0不退券
                        state: 1, // 处理状态 1同意 0不同意
                        remark: this.PB.remark,
                        outMoney: this.PB.outMoney,
                    }
                })

                if (res.code === 2000) {
                    this.posting = false

                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                this.posting = false
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.remark = "";
            this.PB.outMoney = this.replaceItem.money;
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            // this.viewIsReady = false;
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>