<!-- 编辑订单备注 -->
<template>
    <el-dialog class="update-remark"
        :visible="viewIsReady"
        @close="handleClose"
        title="编辑订单备注"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <!-- <el-form-item label="">
                <span class="hint">订单备注信息仅后台管理员可看</span>
            </el-form-item> -->

            <el-form-item label="备注" prop="content">
                <el-input v-model="PB.content"
                    type="textarea"
                    :rows="8"
                    maxlength="400"
                    class="width3"></el-input>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'UpdateRemark',
    props: [ "item" ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                content: ""
            },
            rules: {}
        }
    },

    computed: {
        replaceItem () {
            return this.item || {}
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB.content = item.content || "";
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".update-remark .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "api/order/updateContent",
                    method: "post",
                    data: {
                        orderId: this.item.id,
                        content: this.PB.content
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change", this.PB.content);
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.content = "";
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>