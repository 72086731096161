import forList from "@/mixin/forList";
import Phones from "@/components/Phones.vue";


const searchTypeList = [
    {
        value: 1,
        name: "客户编号"
    },
    {
        value: 2,
        name: "客户姓名"
    },
    {
        value: 3,
        name: "电话号码"
    },
    {
        value: 4,
        name: "订单编号"
    }
];

const stateList = [
    {
        value: 1,
        name: "待处理"
    },
    {
        value: 2,
        name: "已同意"
    },
    {
        value: 3,
        name: "已撤销"
    },
    {
        value: 4,
        name: "已拒绝"
    }
];

export default {
    name: "afterSales",
    mixins: [forList],
    components: { Phones },


    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchType: 1,
                searchValue: "",
                searchState: "",
                searchStartTime: "",         // 开始时间
                searchEndTime: "",           // 结束时间
            },
            exporting: false, // 导出中

            //#region 选项配置
            searchTypeList,        // 查询条件
            typeList: [],          // 类型
            stateList,             // 状态
            dialogDetailVisible: false,
            profile: {},
            activities: [],
            detailLoading: false,
            imgList: [],
            phoneList: [],
        };
    },

    computed: {
        buttonFreezing() {
            return this.loading || this.exporting
        },
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.custId
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        clearSP_trim () {
            this.SP_.searchType = 1
        },

        //详情
        handleDetailBtn(row) {
            this.$router.push(`/order/list/${row.orderid}/${null}`);
        },

        //详情
        handleWorkBtn(row) {
            if (row.state !== 2 && row.state !== 4) {
                this.validateData(row)
            } else {
                this.$router.push(`/order/aftersales/${row.id}`);
            }
        },
        // .立即处理：验证是否在支付日期3天内（完成支付的第二天算第一天），是允许处理，不是则提示“您仅可处理3天内下单的客户售后申请”。白名单的岗位可不验证时间
        async validateData(row) {
            let _this = this
            let id = row.id;
            if (!id) return;
            _this.loading = true;
            try {
                const res = await _this.$axios({
                    method: "post",
                    url: "api/orderAfterSales/queryOrderAfterSalesInfo",
                    data: { id }
                });
                if (res.code !== 2000 && res.code !== 1003) throw res;
                _this.loading = false;
                _this.$router.push(`/order/aftersales/${row.id}`);
            } catch (reason) {
                _this.loading = false;
                console.warn(reason);
                _this.$message({
                    message: reason.msg || "获取信息失败，请稍候再试",
                    type: "warning"
                })
            }
        },

        async fetchData() {

            if (this.loading) return;
            this.loading = true;
            const res = await this.$axios({
                url: "api/orderAfterSales/queryOrderAfterSalesList",
                params: { ...this.extractParams() }
            })

            if (res.code === 2000 && res.data.orderAfterSalesList) {
                this.total = res.data.count;
                this.tableData = res.data.orderAfterSalesList.map(item => {
                    item.createTimeNameHtml = item.createTimeName?.replace(" ", "<br>") || "";
                    return item;
                });
                this.loading = false;
            }
        },
    },

    created() {
        this.auth.detail = this.$hasAuthFor("api/order/queryOrderInfo");
        this.auth.review = this.$hasAuthFor("api/orderAfterSales/queryOrderAfterSalesInfo");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
    }
};