<template>
    <!-- 物流信息编辑窗 -->
    <el-dialog :visible="viewIsReady"
        @close="handleClose"
        title="物流信息"
        width="1000px">

        <el-radio-group v-model="curTab" @change="handleTabSwitch">
            <el-radio v-if="auth.save" label="">添加物流</el-radio>
            <el-radio :label="1">查看物流信息</el-radio>
        </el-radio-group>

        <el-form
            v-if="!curTab"
            class="private"
            label-width="150px"
            label-position="right"
            label-suffix=":"
            :model="PB"
            :rules="rules"
            ref="form"
        >
            <el-form-item label="物流单号" prop="express">
                <el-input v-model.trim="PB.express" maxlength="50"
                    class="width3"></el-input>
            </el-form-item>
             <el-form-item label="发货的服务商" prop="merchantId">
                <el-select  clearable v-model="PB.merchantId" class="width3"> 
                    <el-option
                        v-for="o in merchantArr"
                        :key="o.id"
                        :label="o.serviceName"
                        :value="o.id"
                    ></el-option>
                </el-select>
            </el-form-item>


            <!-- <div v-if="expressData.expressName">
                <el-form-item label="物流公司">
                    {{ expressData.expressName }}
                </el-form-item>
            </div> -->

            <el-form-item label="选择商品" class="wrap" required v-if="order">
                <div style="width: 100%">
                    <el-checkbox
                        :indeterminate="isIndeterminate"
                        v-model="checkAll"
                        @change="handleCheckAllChange"
                    >
                        <span style="color: #333">全选</span>
                    </el-checkbox>
                </div>

                <el-checkbox-group
                    v-model="PB.orderGoodsId"
                    @change="handleCheckedGoodChange"
                >
                    <el-checkbox
                        v-for="goods in order.orderGoodsList"
                        :label="goods.id"
                        :key="goods.id"
                    >
                    <div class="check-label">
                        <span class="goods-name">{{goods.goods_name}}</span>
                        <span class="goods-count">数量: {{goods.num}}</span>
                    </div>
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>

        <div v-else>
            <el-button v-if="auth.delete"
                @click="handleBulkDelete"
                type="primary"
                class="save-btn"
                :disabled="!checkedlist.length"
            >批量删除</el-button>
        
            <el-table :data="expressList" @selection-change="handleSelectionChange">
                <el-table-column v-if="auth.delete" type="selection"></el-table-column>
                <el-table-column label="单号" prop="express_no" class-name="express_no" ></el-table-column>
                <el-table-column label="物流公司" prop="express_com" width="120"></el-table-column>
                <el-table-column label="商品名称" prop="goodsname"></el-table-column>
                <el-table-column label="物流信息" min-width="260px">
                    <template slot-scope="scope">
                        <div class="process-item"
                            v-for="(item, j) in scope.row.expressData"
                            :key="j + item.ftime"
                        >
                            {{item.ftime}} {{item.context}}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div slot="footer">
            <el-button @click="handleClose">关闭</el-button>
            <el-button v-if="!curTab"
                @click="handleSave"
                type="primary"
                class="save-btn"
                :disabled="!PB.orderGoodsId.length"
            >保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { Loading } from 'element-ui';
const rules = {
    express: {
        required: true,
        message: "请输入物流单号",
        trigger: "blur"
    },
    // merchantId: {
    //     required: true,
    //     message: "请选择发货的服务商",
    //     trigger: "blur"
    // },
};
export default {
    name: 'ExpressPop',
    props: ["order",'merchantArr'],

    data () {
        return {
            viewIsReady: false,
            curTab: "",
            checkAll: false,
            isIndeterminate: false,
            PB: {
                express: "",
                orderGoodsId: [],
                merchantId:'',
            },
            rules,
            expressList: [],
            checkedlist: [], // 选中的物流单号
            auth: {
                save: false,
                delete: false
            },
        }
    },
    
    created() {
		this.auth.save = this.$hasAuthFor("api/order/saveExpress");
		this.auth.delete = this.$hasAuthFor("api/order/deleteOrderExpress");
    },

    watch: {
        order (order, old) {
            if (order && order !== old) {
                this.viewIsReady = true;
                if (this.auth.save) {
                    this.$nextTick().then(() => {
                        this.checkAll = true;
                        this.PB.orderGoodsId = order.orderGoodsList.map(({id})=>id)
                    })
                }
                else {
                    this.curTab = 1;
                    this.handleTabSwitch(1)
                }
            }
            else this.viewIsReady = false;
        }
    },

    methods: {
        
        handleTabSwitch (tab) {
            console.log("切换了tab", tab);
            if (tab) this.fetchData();
        },
        handleCheckAllChange(val) {
            this.PB.orderGoodsId = val ? this.order.orderGoodsList.map(({id})=>id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedGoodChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.order.orderGoodsList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.order.orderGoodsList.length;
        },

        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        fetchData () {
            let loadingRef = Loading.service({
                target: ".express-pop .el-dialog"
            });
            this.$axios({url: "api/order/queryOrderExpress",
                params: {orderId: this.order.id}})
            .then(res => {
                if (res.code === 2000) {
                    const {expressList} = res.data;
                    /**
                     *  express.data: Array<Object>:
                     * 
                     *  context: "包裹已签收！（凭取件码）如有问题请联系胡林：19113565254，投诉电话：028-81943274 特殊时期，每天消杀测温不懈怠，极兔防疫正行动。"
                        ftime: "2022-05-25 18:59:57"
                        time: "2022-05-25 18:59:57"
                    */
                    if (expressList) this.expressList = expressList;
                    else this.expressList = [];
                    
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "获取订单物流信息失败",
                    type: "warning"
                })
                console.warn("获取订单物流信息失败", reason)
            })
            .then(() => {
                // this.viewIsReady = true;
                loadingRef && loadingRef.close()
            })
        },

        handleSave () {
            let loadingRef;
            this.$refs.form.validate()
            .then(() => {
                loadingRef = Loading.service({
                    target: ".express-pop .el-dialog"
                });
                return this.$axios({
                    url: "api/order/saveExpress",
                    method: "post",
                    data: {
                        express: this.PB.express,
                        orderGoodsId: this.PB.orderGoodsId.join(),
                        merchantId: this.PB.merchantId,
                        orderId: this.order.id,
                    }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.PB.express = "";
                    this.PB.orderGoodsId = [];
                    this.checkAll = false;
                    this.isIndeterminate = false;
                    this.curTab = 1;
                    this.handleTabSwitch(1)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            
            .catch(reason => {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            })
        },

        handleBulkDelete () {
            const {checkedlist} = this;
            this.$confirm(`确定要删除这${checkedlist.length}个物流单号吗`, "提示")
            .then(() => {
                this.operating = true;
                return this.$axios({
                    url: "api/order/deleteOrderExpress",
                    method: "post",
                    data: { ids: checkedlist.map(order => order.id).join() }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.fetchData();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "error"
                    })
                }
            })
            .then(() => this.operating = false)
        },

        resetData () {
            this.curTab      = "";
            this.PB.express  = "";
            this.expressList = [];
            this.checkedlist = [];
        },

        handleClose () {
            this.$refs.form?.clearValidate();
            this.resetData();
            this.$emit("update:order", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .el-radio-group {
        margin-top: -10px;

        + * {
            margin-top: 20px;
        }
    }

    .el-checkbox {
        display: block;
        width: fit-content;
    }

    .check-label {
        display: flex;
        justify-content: space-between;
        min-width: 306px;
        color: #333333;
    }
    .goods-count {
        color: #999;
    }

    /deep/ {
        .express_no .cell {
            // min-width: 138px;
        }

        .el-radio-group {
            border-bottom: 1px solid #EEEEEE;
        }
        
        .el-radio {
            position: relative;
            height: 100%;
            &.is-checked {
                .el-radio__label {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -2px;
                        border-bottom: 2px solid #CD944D;
                    }
                }
            }
        }

        .el-radio__input {
            display: none;
        }

        .el-radio__label {
            padding-left: 0;
            font-size: 16px;
        }
    }

    .process-item {
        position: relative;
        padding-left: 16px;
        line-height: 16px;
        font-size: 12px;

        &:nth-child(n+2) {
            margin-top: 8px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 4px;
            width: 4px;
            height: 4px;
            border: 2px solid #CD944D;
            border-radius: 50%;
        }
    }
</style>