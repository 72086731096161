<!-- 批量上传订单物流号 -->
<template>
    <el-dialog :visible="!!show" title="批量上传订单物流号"
            @close="handleIssueClose" width="600px" class="batch-express">

        <el-form
            v-if="!resultFilePath"
            label-suffix=": "
            label-width="150px"
            class="private" ref="issue"
            :model="issueData"
            :rules="rules"
            @submit.prevent="handleBulkIssue">

            <el-form-item label="上传订单物流号" prop="cusfile" required class="issue-upload-feild">
                <el-upload
                    class="upload-row"
                    :action="$baseURL + 'api/common/fileSingleUpLoad'"
                    :auto-upload="false"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :data="{test: 1}"
                    :limit="1"
                    :file-list="issueData.cusfile"
                    :on-exceed="handleExceed"
                    :on-change="handleSelectFile"
                    :on-remove="handleRemoveFile"
                >
                    <el-button size="small">选择文件</el-button>
                </el-upload>
                <a download target="_blank" :href="sampleFileUrl">下载模板</a>
            </el-form-item>
             <el-form-item label="发货的服务商" prop="merchantId">
                <el-select  clearable v-model="issueData.merchantId" class="width3"> 
                    <el-option
                        v-for="o in merchantArr"
                        :key="o.id"
                        :label="o.serviceName"
                        :value="o.id"
                    ></el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <div v-else class="result"> 上传成功！</div>

        <div slot="footer">
            <el-button @click="handleIssueClose">{{ resultFilePath ? "关闭" : "取消"}}</el-button>
            <el-button @click="handleBulkIssue" type="primary">{{ resultFilePath ? "查看执行文件" : "确定"}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import axios from 'axios';
import { Loading } from 'element-ui';
export default {
    name: 'BatchExpress',
    props: {
        show: Boolean,
        merchantArr:Array,
    },

    data () {
        return {
            issueData: {
                cusfile: [],
                merchantId:'',
            },
            sampleFileUrl: this.$baseURL + "/upload/winktemp/orderexpersstemp.xlsx",
            rules: {
                cusfile: { required: true, message: "请上传订单物流号表格" }
            },

            resultFilePath: "", // 结果文件地址
        }
    },

    methods: {
        resetForm () {
            this.issueData.cusfile = [];
            this.issueData.merchantId = ''
            this.resultFilePath = "";
        },

        handleIssueClose () {
            if (this.resultFilePath) {
                this.$emit("refresh")
            }
            this.$emit("update:show", false);
            this.resetForm();
        },

        handleBulkIssue () {
            if (this.resultFilePath) {
                const link = document.createElement("a");
                    link.target = "_blank";
                    link.download = ""
                    link.href = this.resultFilePath;
                link.click();
                // return this.handleIssueClose();
            }

            let loadingRef;
            this.$refs.issue.validate()
            .then(() => {
                loadingRef = Loading.service({
                    target: ".batch-express .el-dialog"
                })
                const data = new FormData();
                const {cusfile,merchantId} = this.issueData;
                data.append("orderfile", cusfile[0]);
                data.append("merchantId", merchantId);

                return axios.post(this.$baseURL+"api/order/batchSaveOrderExpress", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        token: this.$store.state.token
                    }
                })
            })
            .then(res => {
                if (res.data.code === 2000) {
                    const resultFilePath = res.data.data.path;
                    if (resultFilePath) {
                        this.resultFilePath = this.$baseURL + resultFilePath;
                    } else {
                        this.handleIssueClose();
                        this.$message({
                            message: "上传成功",
                            type: "success"
                        })
                    }
                    loadingRef && loadingRef.close();

                } else if (res.data?.code === 1003) {
                    this.$store.commit("clearUserInfo");
                    this.$message({
                        message: "登录过期，请重新登录",
                        type: "warning"
                    });
                    return Promise.reject(false);

                } else return Promise.reject(res.data);
            })
            .catch (reason => {
                console.warn("上传失败", reason);
                if (reason !== false) {
                    this.$message({
                        message: reason?.msg || "发放失败",
                        type: "error"
                    })
                }
                loadingRef && loadingRef.close();
            })
        },
        handleExceed () {
            this.$message({
                message: "只能上传一个表格",
                type: "error"
            })
        },
        handleSelectFile (e) {
            this.issueData.cusfile.push(e.raw)
        },
        handleRemoveFile (e) {
            const index = this.issueData.cusfile.indexOf(e);
            this.issueData.cusfile.splice(index, 1)
        }

    }
}
</script>

<style lang='scss' scoped>
    .issue-upload-feild {
        /deep/.el-form-item__content {
            width: 300px;
            position: relative;
        }

        a {
            position: absolute;
            right: 0;
            color: #cd944d;
        }
    }
    .result {
        text-align: center;
    }
</style>