<template>
    <!--  -->
    <el-dialog :visible="viewIsReady"
        @close="handleClose"
        title="强制扣积分"
        width="600px"
        class="force-pop"
    >

    <p class="page-tips" style="padding:0 30px;line-height:16px;">请谨慎使用强制扣积分，避免后期客户与公司发生争议造成严重影响。</p>
    <div class="flex content" v-if="order&&custIntegralInfo">
        <div class="item">
            <div style="color:gray">客户编号：</div>
            <div>{{order.cust_code}}</div>
        </div>
        <div class="item">
            <div>客户姓名：</div>
            <div>{{order.cust_name}}</div>
        </div>
        <div class="item">
            <div>可用积分：</div>
            <div>{{custIntegralInfo.integral}}</div>
        </div>
        <div class="item">
            <div>订单编号：</div>
            <div>{{order.order_no}}</div>
        </div>
        <div class="item">
            <div>下单时间：</div>
            <div>{{order.createtime}}</div>
        </div>
        <div class="item" v-if="order.agent_emp_code">
            <div>代下单员工：</div>
            <div>{{order.agent_emp_name}}（{{order.agent_emp_code}}）</div>
        </div>
        <div class="item">
            <div>待支付积分：</div>
            <div>{{order.order_price}}</div>
        </div>
    </div>




        

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" v-dbclick
                type="primary" class="save-btn"
            >确认支付</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ForcePop',
    props: ["order"],

    data () {
        return {
            viewIsReady: false,
            custIntegralInfo:null,
        }
    },

    watch: {
        order (order, old) {
            if (order && order !== old) {
                this.viewIsReady = true;
                this.$nextTick().then(this.fetchData)
            }
            else this.viewIsReady = false;
        }
    },

    methods: {
         async fetchData() {
            let loadingRef = this.$loading({
                target: ".force-pop .el-dialog"
            });

            let params = {
                custId:this.order.cust_id
            }
            try {
                const res = await this.$axios({
                    url: "api/customer/queryCustIntegralInfo",
                    params
                });
    
                if (res.code === 2000) {
                    this.custIntegralInfo = res.data.custMap
                } else if (res.code !== 1003) {
                    return this.$message({
                        message: res.msg || "获取数据失败",
                        type: "warning"
                    })
                }
            } catch (reason) {
                console.warn(reason);
            }
            loadingRef.close();


        },
        async handleSave () {
            let loadingRef = this.$loading({
                target: ".force-pop .el-dialog"
            });

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "api/order/payCustOrder",
                    data: {orderId: this.order.id}
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "支付成功",
                        type: "success"
                    })
                }
                else if (res.code !== 1003) throw(res)

            } catch (reason) {
                this.$message({
                    message: reason.msg || "操作失败",
                    type: "warning"
                })
                console.warn("操作失败", reason)
            }

            loadingRef.close();
            this.handleClose();
        },
        handleClose () {
            this.$emit("update:order", null);
        }
    }
}
</script>

<style lang='scss' scoped>
.flex{
    display: flex;
    flex-wrap: wrap;
}
.content{
    padding:0 30px;
}
.content .item{
    width: 50%;
    display: flex;
    margin-bottom: 16px;

}
    

</style>