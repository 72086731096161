<template>
    <!-- 公众号提醒 -->
    <el-dialog :visible="viewIsReady"
        @close="handleClose"
        title="温馨提示"
        width="600px"
        class="remind-pop"
    >

        您确认通过公众号给用户推送订单账号么？

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" v-dbclick
                type="primary" class="save-btn"
            >保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'RemindPop',
    props: ["order"],

    data () {
        return {
            viewIsReady: false
        }
    },

    watch: {
        order (order, old) {
            if (order && order !== old) {
                this.viewIsReady = true;
                this.$nextTick().then(this.fetchData)
            }
            else this.viewIsReady = false;
        }
    },

    methods: {
        async handleSave () {
            let loadingRef = this.$loading({
                target: ".remind-pop .el-dialog"
            });

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "api/order/sendOfficialMessage",
                    data: {orderId: this.order.id}
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "通知成功",
                        type: "success"
                    })
                }
                else if (res.code !== 1003) throw(res)

            } catch (reason) {
                this.$message({
                    message: reason.msg || "操作失败",
                    type: "warning"
                })
                console.warn("操作失败", reason)
            }

            loadingRef.close();
            this.handleClose();
        },

        // resetData () {},

        handleClose () {
            // this.resetData();
            this.$emit("update:order", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .el-radio-group {
        margin-top: -10px;

        + * {
            margin-top: 20px;
        }
    }

    .el-checkbox {
        display: block;
        width: fit-content;
    }

    .check-label {
        display: flex;
        justify-content: space-between;
        min-width: 306px;
        color: #333333;
    }
    .goods-count {
        color: #999;
    }

    /deep/ {
        .express_no .cell {
            min-width: 138px;
        }

        .el-radio-group {
            border-bottom: 1px solid #EEEEEE;
        }
        
        .el-radio {
            position: relative;
            height: 100%;
            &.is-checked {
                .el-radio__label {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -2px;
                        border-bottom: 2px solid #CD944D;
                    }
                }
            }
        }

        .el-radio__input {
            display: none;
        }

        .el-radio__label {
            padding-left: 0;
            font-size: 16px;
        }
    }

    .process-item {
        position: relative;
        padding-left: 16px;
        line-height: 16px;
        font-size: 12px;

        &:nth-child(n+2) {
            margin-top: 8px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 4px;
            width: 4px;
            height: 4px;
            border: 2px solid #CD944D;
            border-radius: 50%;
        }
    }
</style>