<!-- 同意退单 -->
<template>
    <el-dialog class="reject-requisition"
        :visible="show"
        @close="handleClose"
        title="处理售后"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="拒绝理由" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="200"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleSave" type="primary">确定</el-button>
            <el-button @click="handleClose">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'RejectRequisiton',
    props: [ "show", "item" ],

    data () {
        return {
            PB: {
                remark: ""
            },
            rules: {
                remark: { required: true, message: "请输入拒绝理由" }
            },
            posting:false,
        }
    },

    computed: {
        replaceItem () {
            return this.item || {}
        }
    },

    watch: {},

    methods: {
        async handleSave () {
            if(this.posting)return
            this.posting = true
            const loadingRef = this.$loading({
                target: ".reject-requisition .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "api/orderAfterSales/updateOrderAfterSalesState",
                    method: "post",
                    data: {
                        id: this.item.id,
                        type : 0, // 同意类型 1退券 0不退券
                        state: 0, // 处理状态 1同意 0不同意
                        remark: this.PB.remark
                    }
                })

                if (res.code === 2000) {
                    this.posting = false
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                this.posting = false
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.remark = "";
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            // this.viewIsReady = false;
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>