import forList from "@/mixin/forList";
import EditableState from "@/components/EditableState.vue";
import ExpressPop from "./ExpressPop.vue";
import BatchExpress from "./BatchExpress.vue";
import TurnToYimin from "./TurnToYimin.vue";
import sendStudyOrder from "./sendStudyOrder.vue";
import RemindPop from "./RemindPop.vue";
import ForcePop from "./ForcePop.vue";
import WxLink from "./WxLink.vue"
import Phones from "@/components/Phones.vue";
const goodsAreaEnum = [
    {name: "中国区", value: 1},
    {name: "澳洲区", value: 2}
];
const isSendYmEnum = [
    {name: "是", value: 1},
    {name: "否", value: 0}
];

export default {
    name: 'OrderList',
    mixins: [forList],
    components: {
        "editable-state": EditableState,
        "express-pop": ExpressPop,
        "batch-express": BatchExpress,
        "turn-to-yimin": TurnToYimin,
        "send-study-order": sendStudyOrder,
        "remind-pop": RemindPop,
        "force-pop": ForcePop,
        "wx-link": WxLink,
        Phones
    },

    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchType: 1,
                searchValue: "",
                searchSys: "",         // 业务方向
                searchArea: [],        // 区域
                searchState: [],       // 订单状态
                searchPayState: "",    // 支付方式 1-积分 2-微信 3-优惠券支付
                searchStartTime: "",   // 开始时间
                searchEndTime: "",     // 结束时间
                searchGoodsArea: "",   // 商品区域
                searchIsSendYm: "",    // 是否转文案
                searchStartTotal: "", // 订单总和左数
                searchEndTotal: "",   // 订单综合右数
                searchTimeType: '1', //1：下单时间 2：支付时间
                searchIsExpress: '', //

            },
            isExpressArr: [
                { name: '未发货', value: 1 },
                { name: '已发货', value: 2 },
                { name: '已签收', value: 3 },
                { name: '未签收', value: 4 },

            ],

            OrderSearchTypeEnum: [],   // 搜索条件下拉集合
            sysTypeList:[],            // 业务方向
            areaList:[],               // 选中的业务方向的区域配置
            SearchOrderStateEnum:[],   // 订单状态选项
            payTypeEnum:[],            // 支付类型集合
            goodsAreaEnum,             // 商品区域配置
            isSendYmEnum,              // 是否转文案配置

            // 表格项（行）的操作菜单
            actionList: [
                {
                    label: "详情",
                    name: "detail",
                    banned: false
                },
                {
                    label: "修改积分",
                    name: "price",
                    banned: false
                },
                {
                    label: "补差价",
                    name: "diff",
                    banned: false
                },
                {
                    label: "退单/退积分",
                    name: "return",
                    banned: false
                },
                {
                    label: "物流跟进",
                    name: "express",
                    banned: false
                },
                {
                    label: "公众号提醒支付",
                    name: "remind",
                    banned: false
                },
                {
                    label: "获取订单支付地址",
                    name: "link",
                    banned: false
                },
                {
                    label: "强制扣积分",
                    name: "forcePay",
                    banned: false
                },

            ],

            /** 对话框数据：
             *  {
             *      type: 2,
             *      order: {},   // 操作的订单的数据
             *      value: [],   // 对话框的数据
             *      remark: "",  // 备注
             *      ?tab: "",    // 当前tab
             *      ?btnTex: ""  // 按钮文字配置
             *  }
             */
            popupData: null,

            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
            logLoading: false,  // 订单日志获取中
            logList: null,      // (当前对话窗显示的)订单日志列表
            expressOrder: null, // 物流信息操作窗的订单id
            remindOrder: null,
            forceOrder: null,
            linkOrder: null,
            showBatchExpress: false, // 打开批量上传物流单号的操作窗
            expandRowKeys: [],  // 展开所有
            expandAll: false,
            turnData: null,     // 转文案中心的商品
            sendData: null,     // 转文案中心的商品
            phoneList: [],
            merchantArr: [],
        }
    },

    computed: {
        currentCustomerSearchType () {
            return this.OrderSearchTypeEnum.find(item => item.value === this.SP_.searchType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
        
        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        },

        // 对话框状态由 popupData 是否有值产生
        showPopup () {
			return !!this.popupData
		},
        popupTitle () {
            if (this.popupData) {
                // if (this.popupData.type === "price")
                return this.actionList[this.popupData.type].label
            }
            return ""
        },
        popupBtnText () {
            const result = ["取消", "保存"];
            if (
                this.popupData.btnTex?.constructor === Array &&
                typeof this.popupData.tab === "number"
            ) {
                const opt = this.popupData.btnTex[this.popupData.tab];
                result[0] = opt.cancel || result[0]
                result[1] = opt.confirm || result[1]
                if (opt.confirm1) result.push(opt.confirm1)
            }
            return result
        },
        /**
         * 检查对话框是否可以点击确认按钮：
         * @returns Boolean
         * 对于退单操作，常是；
         * 其他操作：检查对话框是否填写了至少一个值
         */
        popupFillOk () { 
            if (this.showPopup) {
                if (this.popupData.tab === 0) {
                    return true;
                }
                return this.popupData.value.some(val => !!val)
            } else {
                return false
            }
        },

        
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.cust_id
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        // 从缓存中恢复页面查询参数后，需要后续执行的函数（见 @/mixin/clearListPageStateOnCreated）
        callBackForListState (params) {
            if (params.searchSys)
            this.handleSysChange(params.searchSys)
        },

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数
            try {
                const res = await this.$axios({
                    url: "api/order/queryOrderList",
                    params
                });
    
                if (res.code === 2000) {
                    const list = res.data?.orderList;
                    this.expandRowKeys = [];
                    // 解析订单数据
                    list.forEach(order => {
                        // 所属商品数据
                        order.couponList = [];
                        order.orderGoodsList?.forEach(goods => {
                            if (goods.coupon_name) order.couponList.push({
                                coupon_name: goods.coupon_name,
                                coupon_price: goods.coupon_price
                            });
                            if (goods.goods_id === 50) goods.outconfigId = 1; // 调试的
                            try {
                                if (goods.conf_value) {
                                    // 前期是以键值对的形式保存的，后来改为Aarry<Object>了，为了兼容
                                    // 前期的数据，这里将现在的数据结构转化为元数据结构。
                                    // 2022年后，可以对这部分兼容代码优化，因为已经没有老的测试数据了
                                    let conf_value = JSON.parse(goods.conf_value)
                                    conf_value.forEach(field => {
                                        if (field.type === 8) field.value = field.value?.map(image => {
                                            image.path = this.$baseURL + image.path
                                            return image;
                                        })
                                    })
                                    goods.conf_value = conf_value;
                                }

                                // if (goods.type === 1) order.haveMailGoods = true;
                            } catch (reason) {
                                console.warn("商品模板字符串JSON解析失败", goods)
                            }
                        });

                        // 可操作选项
                        order.actionList = [this.actionList[0]];
                        if ( // 订单状态为：待支付，已支付，已完成的才可修改积分
                            (order.state === 1 ||
                            // order.state === 2 ||
                            order.state === 3) &&
                            order.pay_way === 1
                        ) order.actionList.push(this.actionList[1])
                        if ( // 待支付、已退单的不能补差价
                            order.state !== 1 &&
                            order.state !== 7 &&
                            order.pay_way === 1
                        ) order.actionList.push(this.actionList[2])
                        if ( // 订单状态为已支付，已完成的才能进行退单，微信支付的不能进行退单
                            (order.state === 2 ||
                            order.state === 10) &&
                            order.pay_way === 1
                        ) order.actionList.push(this.actionList[3])
                        if ( // 实物商品才有物流信息，待支付的没有
                            order.state !== 1 &&
                            order.mail_type === 2
                        ) order.actionList.push(this.actionList[4])
                        order.actionList.push(this.actionList[5], this.actionList[6], this.actionList[7])

                        if (this.expandAll) this.expandRowKeys.push(order.id)
                    });

                    list && this.tableData.splice(0, this.tableData.length, ...list);
                    this.total = res.data.count;

                } else if (res.code !== 1003) {
                    return this.$message({
                        message: res.msg || "获取列表失败",
                        type: "warning"
                    })
                }

                this.loading = false;
            } catch (reason) {
                console.warn(reason);
                this.loading = false;
            }

        },

        // 清空 SP_: 将高级搜索的字段恢复为默认值（见 data.SP_）
        clearSP_ () {
            for (let key in this.SP_) {
                const oVal = this.SP_[key];
                switch (key) {
                    case "pageSize":
                    case "pageNum":
                        break;

                    case "searchType":
                    case "fuzzyQueryType":
                        this.SP_[key] = 1;
                        break;
                    case "searchTimeType":
                        this.SP_[key] = '1';
                        break;
                    default: 
                        if (oVal.constructor === Array) this.SP_[key] = [];
                        else if (oVal.constructor === Object) this.SP_[key] = {};
                        else this.SP_[key] = "";
                        break;
                }
            }
        },

        // 获取配置配置参数
        async fetchConfig() {
            try {
                const res = await this.$axios({ url: "api/order/getConditionFieldForOrderList" })
                if (res.code === 2000) {
                    /**
                     * res.data:
                     *   SearchOrderStateEnum 订单状态选项
                     *   sysTypeList:         业务方向选项
                     *   payTypeEnum          支付类型集合
                     */
    
                    // 把获取的配置列表设为 data 中对应的配置列表
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                } else if (res.code !== 1003) {
                    console.warn("获取参数失败")
                }
            } catch (reason) {
                console.warn(reason)
            }
        },

        // 选中业务方向时
        handleSysChange(sysType) {
            this.SP_.searchArea = [];

            // 获取区域列表
            if (!sysType) return console.warn("没有业务id，无法获取区域列表");

			this.$axios({
				url: "api/customer/getRegionsBySysType",
				params: {sysType}
			})

			.then(res => {
                const list = res.data?.arealist
				if (list) {
					this.areaList.splice(0, this.areaList.length, ...list)
				} else {
					return Promise.reject(res);
				}
			})

			.catch(reason => console.log(reason))
			
		},

        handleAction (action, scope) {
            const type = action.name;
            const data = scope.row;
            if (type === "detail") {
                this.$router.push(`/order/list/${data.id}/${null}`);

            } else if (type === "express") {
                this.expressOrder = scope.row

            } else if (type === "remind") {
                this.remindOrder = scope.row
                // this.officalRemind(scope.row);

            } else if (type === "link") {
                this.linkOrder = scope.row

            } else if (type === "forcePay") {
                this.forceOrder = scope.row
            } else {
                const popupData = {
                    order: data,
                    type: this.actionList.findIndex(item => item.name === type),
                    value: [],
                    remark: ""
                }
                
                if (type === "return") {
                    // 退单/退积分：默认隐藏填写表单，点击退积分才展示填写项
                    // 点击退单就直接更新状态了。
                    if (this.auth.return) popupData.tab = 0;
                    else popupData.tab = 1;
                    // 和tab顺序对应的确认按钮文字和取消按钮文字
                    popupData.btnTex = [
                        {
                            confirm: "仅退单不退劵",
                            confirm1: "退单退所有劵"
                        },
                        {
                            confirm: "确定退部分积分"
                        }
                    ]
                }
    
                this.popupData = popupData;
            }
        },

        // 批量操作：完成
        handleBulkComplete () {
            const {checkedlist} = this;
            if (checkedlist.some(item => item.state !== 2))
                return this.$message({
                    message: "只有已支付的订单才能进行批量完成操作",
                    type: "error"
                })
            
            this.$confirm(`确定要完成这${checkedlist.length}个订单吗`, "提示")
            .then(() => {
                this.operating = true;
                return this.$axios({
                    url: "api/order/batchOrderOk",
                    method: "post",
                    data: { orderIds: checkedlist.map(order => order.id).join() }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    this.fetchData();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "error"
                    })
                }
            })
            .then(() => this.operating = false)
        },

        // 批量操作：删除
        handleBulkRemove () {
            const {checkedlist} = this;
            this.$confirm(`确定要删除这${checkedlist.length}个订单吗`, "提示")
            .then(() => {
                this.operating = true;
                return this.$axios({
                    url: "api/order/batchDeleteOrder",
                    method: "post",
                    data: { orderIds: checkedlist.map(order => order.id).join() }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.fetchData();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "error"
                    })
                }
            })
            .then(() => this.operating = false)
        },

        // 点击导出
        handleExportBtn(e, byGoods) {
            // 检查有无时间条件，没有的话不能导出
            const {searchStartTime, searchEndTime} = this.SP_;
            if (!(searchStartTime || searchEndTime)) {
                if (!this.showAdvanceSearch) this.handleAdvancedSearchBtn();
                return setTimeout(() => this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                }), 100);
            }

            this.operating = true;
            const url = byGoods ? "api/download/downGoodsOrder" :
                "api/download/downOrder";
            const data = this.extractParams(true);

            this.$oxios({ url, method: "post", data: {content: JSON.stringify(data)} })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "导出失败",
                    type: "error"
                })
            })
            .then(() => this.operating = false)
        },

		// 关闭对话框
		handleDialogClose() {
			this.popupData = null;
		},

        // 对话框的确认按钮
        handSubmitButton(btnIndenty, goodsrow) {
            // orderGoodsId
            // console.log(goodsrow);

            let { type, order, value, remark, tab, btnTex } = this.popupData;
            value = value.map(val => Number(val));

            if (type === 3 && tab === 0) { // 退单
                let text;
                // if (btnIndenty == 2) text = `您确定要退回${order.order_price}积分和已使用的券么？`;
                // else text = `您确定要退单么？退单即可退回${order.order_price}积分。`

                // text = `您确定要退单么？退单即可退回${order.order_price}积分。`
                if (goodsrow) {
                    text = `您确定要退单么？退单即可退回${goodsrow.sale_price}积分。`
                } else {
                    text = `您确定要退单么？退单即可退回${order.order_price}积分。`
                }

                return this.$confirm(text, '提示')
                    .then(res => {
                        // if (res.confirm) {
                        return this.doReturnOrder({
                            // orderId: order.id,
                            orderGoodsId: goodsrow ? goodsrow.id : null,
                            type: btnIndenty == 2 ? 1 : 0
                        })
                        // }
                    })
                    .then(success => {
                        if (success) {
                            this.fetchData();
                            this.popupData = null;
                            // this.operating = false;
                        }
                    })
                    .catch(reason => console.warn("处理退单操作时发生错误", reason))
            }

            // post 数据
            const data = {
                diffOrderStr: [],
                orderId: order.id,
                diffOrderRemark: remark,
                // 1退部分积分，2修改积分，其他补差价
                isOut: type === 1 ? 2 : type === 3 ? 1 : undefined
            };

            for (let i = 0; i < value.length; i++) {
                const val = value[i];
                // 空值抛弃。
                if (val == undefined || val === "") continue;
                if (!Number.isInteger(val) || val < 0) return this.$message({
                    message: "请输入正整数",
                    type: "warning"
                })
                const goods = order.orderGoodsList[i];

                if (type === 1 && value[i] < 0) { // 修改积分（价格）
                    // 修改后的积分必须大于0
                    this.$message({
                        message: "现积分请输入大于0的值",
                        type: "error"
                    })
                    return this.$refs[`input${i}`].focus()

                } else if (type === 3 && val > goods.sale_price) { // 退积分
                    // 退还的积分不能大于已支付的积分
                    this.$message({
                        message: "退还的积分不能大于已支付的积分",
                        type: "error"
                    })
                    return this.$refs[`input${i}`].focus()

                } else if (type === 2 && val < goods.sale_price) { // 补差价
                    // 修改后的积分必须大于已支付的积分
                    this.$message({
                        message: "现需支付积分必须大于等于已支付积分，如需退积分请使用退积分功能",
                        type: "error"
                    })
                    return this.$refs[`input${i}`].focus()
                }

                data.diffOrderStr.push({
                    orderGoodsId: goods.id,
                    currPrice: val,
                    goodsName: goods.goods_name
                });
            }

            return this.doSetOrderPrice(data)
                .then(success => {
                    if (success) {
                        this.fetchData();
                        this.popupData = null;
                        this.operating = false;
                    }
                })
        },
        handSubmitButtonOld(btnIndenty) {
            let { type, order, value, remark, tab, btnTex } = this.popupData;
                value = value.map(val => Number(val));

            if (type === 3 && tab === 0) { // 退单
                let text;
                if (btnIndenty == 2) text = `您确定要退回${ order.order_price }积分和已使用的券么？`;
                else text = `您确定要退单么？退单即可退回${ order.order_price }积分。`
                return this.$confirm(text, '提示')
                .then(res => {
                    // if (res.confirm) {
                        return this.doReturnOrder({
                            orderId: order.id,
                            type   : btnIndenty == 2 ? 1 : 0
                        })
                    // }
                })
                .then(success => {
                    if (success) {
                        this.fetchData();
                        this.popupData = null;
                        // this.operating = false;
                    }
                })
                .catch(reason => console.warn("处理退单操作时发生错误", reason))
            }

            // post 数据
            const data = {
                diffOrderStr: [],
                orderId: order.id,
                diffOrderRemark: remark,
                // 1退部分积分，2修改积分，其他补差价
                isOut: type === 1 ? 2: type === 3 ? 1 : undefined
            };

            for (let i = 0; i < value.length; i++) {
                const val = value[i];
                // 空值抛弃。
                if (val == undefined || val === "") continue;
                if (!Number.isInteger(val) || val < 0) return this.$message({
                    message: "请输入正整数",
                    type: "warning"
                })
                const goods = order.orderGoodsList[i];

                if (type === 1 && value[i] < 0) { // 修改积分（价格）
                    // 修改后的积分必须大于0
                    this.$message({
                        message: "现积分请输入大于0的值",
                        type: "error"
                    })
                    return this.$refs[`input${i}`].focus()

                } else if (type === 3 && val > goods.sale_price) { // 退积分
                    // 退还的积分不能大于已支付的积分
                    this.$message({
                        message: "退还的积分不能大于已支付的积分",
                        type: "error"
                    })
                    return this.$refs[`input${i}`].focus()

                } else if (type === 2 && val < goods.sale_price) { // 补差价
                    // 修改后的积分必须大于已支付的积分
                    this.$message({
                        message: "现需支付积分必须大于等于已支付积分，如需退积分请使用退积分功能",
                        type: "error"
                    })
                    return this.$refs[`input${i}`].focus()
                }

                data.diffOrderStr.push({
                    orderGoodsId: goods.id,
                    currPrice: val,
                    goodsName: goods.goods_name
                });
            }

            return this.doSetOrderPrice(data)
            .then(success => {
                if (success) {
                    this.fetchData();
                    this.popupData = null;
                    this.operating = false;
                }
            })

            

            // if (type === 1) { // 修改积分
            //     for (let i = 0; i < value.length; i++) {
            //         const val = value[i];
            //         const goods = order.orderGoodsList[i];
            //         if (value[i] < 0) {
            //             this.$message({
            //                 message: "请输入大于0的值",
            //                 type: "error"
            //             })
            //             return this.$refs[`input${i}`].focus()
            //         }
            //         data.diffOrderStr.push({
            //             orderGoodsId: goods.goods_id,
            //             currPrice: val,
            //             goodsName: goods.goods_name
            //         })
            //     }
            // } else if (type === 2) { // 退单/退积分
            //     if (tab === 0) { // 退单
                    
            //     } else if (tab === 1) {// 退积分
            //     }

            // } else if (type === 3) { // 补差价
            //     for (let i = 0; i < value.length; i++) {
            //         const val = value[i];
            //         const goods = order.orderGoodsList[i];
            //         if (val <= goods.sale_price) {
            //             this.$message({
            //                 message: "修改的积分必须大于等于已支付积分，如需退积分请使用退积分功能",
            //                 type: "error"
            //             })
            //             return this.$refs[`input${i}`].focus()
            //         }
            //         data.diffOrderStr.push({
            //             orderGoodsId: goods.goods_id,
            //             currPrice: val,
            //             goodsName: goods.goods_name
            //         })
            //     }
            // }

            // // function doPost () {
            //     console.log(data);
            // // }
        },

        // 退单
        doReturnOrder (data) {
            if (typeof data !== "object")
                return console.log("退单失败, 没有传入争取的参数", data)
            
            this.operating = true;
            
            return this.$axios({
                url: "api/order/outOrder",
                method: "post",
                data
            })
            .then(res => {
                this.operating = false
                if (res.code === 2000) {
                    this.$message({
                        type: "success",
                        message: "退单成功"
                    });
                    return true;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    type: "error",
                    message: reason.msg || "退单失败"
                });
                console.warn("退单失败", reason);
                this.operating = false
            })
        },

        // 修改积分，补差价，退积分
        doSetOrderPrice (data) {
            if (typeof data !== "object")
                return console.log("修改积分/补差价/退积分失败, 没有传入争取的参数", data)
            // for (let key in data) data[key] = JSON.stringify(data[key])
            this.operating = true;
            return this.$oxios({
                url: "api/order/updateDiffOrder",
                method: "post",
                data
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        type: "success",
                        message: "操作成功"
                    });
                    return true;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    type: "error",
                    message: reason.msg || "操作失败"
                });
                console.warn("操作失败", reason);
            })
        },

        // 点击订单金额：显示订单变更日志
        handlePriceClick (e, index) {
            if (!this.auth.history) return;
            this.logLoading = true;
            // this.logList = [];
            const order = this.tableData[index];
            const url = "api/order/queryOrderHis";
            const params = {orderId: order.id};
            this.$axios({ url, params, method: "get"})
            .then(res => {
                if (res.code === 2000) {
                    const {orderHisList} = res.data;
                    if (orderHisList.length)
                        this.logList = orderHisList;
                    else {
                        // this.logList = null;
                        this.$message({
                            message: "暂无订单变更日志",
                            type: "warning"
                        })
                    }
                } else if (res.code !== 1003) return Promise.reject(res);
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "获取订单日志失败",
                    type: "error"
                });
                console.warn("获取订单日志失败", reason);
            })
            .then(() => {
                this.logLoading = false;
            })
        },

        // 点击关闭订单积分日志
        handleLogClose () {
            this.logLoading = false;
            this.logList = null
        },

        // 点击关闭物流窗
        // handleExpressClose () {
        //     this.expressOrder = null;
        // },

        // 接收批量上传物流单号窗口的刷新请求
        handleExpressRefresh () {
            this.handleSearch()
        },

        handleExpandAll () {
            this.expandAll = !this.expandAll;
            if (this.expandAll) {
                this.expandRowKeys = this.tableData.map(it => it.id)
            } else {
                this.expandRowKeys = []
            }
        },
        handleExpandChange (row, expandList) {
            if (expandList.length === this.tableData.length) this.expandAll = true;
            else if (expandList.length === 0) this.expandAll = false;
        },
        createRowKey (e) {
            return e.id;
        },
        // 转文案中心
        handleTurn (order, goods) {
   
            console.log(goods.outType);
            if (goods.outType == 2) {
                this.sendData = {
                    contractName: '',
                    cust_code: order.cust_code,
                    cust_name: order.cust_name,
                    cust_phone: order.cust_phone,
                    areaName: order.areaNamelist?.join(),
                    ...goods
                }

            } else {
                this.turnData = {
                    cust_code: order.cust_code,
                    cust_name: order.cust_name,
                    cust_phone: order.cust_phone,
                    areaName: order.areaNamelist?.join(),
                    ...goods
                }
            }

            this.turningOrderIndex = this.tableData.indexOf(order);
            this.turningGoodsIndex = order.orderGoodsList.indexOf(goods);
        },
        handleOrderTurn (e) {
            // console.log("转文案中心了", e);
            const {conf_value} = e;
            const order = this.tableData[this.turningOrderIndex];
            const goods = order.orderGoodsList[this.turningGoodsIndex];
            goods.conf_value = conf_value;
            // goods.goods_sku = goods_sku;
            goods.isSendYM = 1;
            this.$set(this.tableData[this.turningOrderIndex].orderGoodsList,
                this.turningGoodsIndex, goods)
        },
        handleOrderSend(e) {
            // console.log("转文案中心了", e);
            const { conf_value } = e;
            const order = this.tableData[this.turningOrderIndex];
            const goods = order.orderGoodsList[this.turningGoodsIndex];
            goods.conf_value = conf_value;
            // goods.goods_sku = goods_sku;
            goods.isSendYM = 1;
            this.$set(this.tableData[this.turningOrderIndex].orderGoodsList,
                this.turningGoodsIndex, goods)
        },
        getmerchantArr() {
            this.$axios({
                url: "api/merchant/queryAllServiceMerchant",
                method: "get",
                // params: { pageSize: 10, pageNum: 1, status: 1 }
            })
                .then(res => {
                    if (res.code === 2000) {
                        this.merchantArr = res.data.serviceMerchants;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: reason.msg || "获取订单物流信息失败",
                        type: "warning"
                    })
                    console.warn("获取订单物流信息失败", reason)
                })
                .then(() => {
                    // this.viewIsReady = true;
                })

        },
    },

    created() {
        this.fetchConfig();
		this.auth.diff = this.$hasAuthFor("api/order/updateDiffOrder");
		this.auth.return = this.$hasAuthFor("api/order/outOrder");
		this.auth.history = this.$hasAuthFor("api/order/queryOrderHis");
		// this.auth.express = this.$hasAuthFor("api/order/saveExpress");
		this.auth.batchExpress = this.$hasAuthFor("api/order/batchSaveOrderExpress");
		this.auth.detail = this.$hasAuthFor("api/order/queryOrderInfo");
		this.auth.delete = this.$hasAuthFor("api/order/batchDeleteOrder");
		this.auth.complete = this.$hasAuthFor("api/order/batchOrderOk");
		this.auth.export = this.$hasAuthFor("api/download/downOrder");
		this.auth.exportGoods = this.$hasAuthFor("api/download/downGoodsOrder");
		this.auth.sendyimin = this.$hasAuthFor("api/order/sendYMOrder");
		this.auth.remind = this.$hasAuthFor("api/order/sendOfficialMessage");
		this.auth.link = this.$hasAuthFor("api/order/getWeiXinUrlLink");
        this.auth.force = this.$hasAuthFor("api/order/payCustOrder");

        if (!this.auth.detail) this.actionList[0].banned = true;
        if (!this.auth.diff) this.actionList[1].banned =
            this.actionList[2].banned = true;
        if (!this.auth.return && !this.auth.diff) this.actionList[3].banned = true;
        // if (!this.auth.express) this.actionList[4].banned = true;
        if (!this.auth.remind) this.actionList[5].banned = true;
        if (!this.auth.link) this.actionList[6].banned = true;
        if (!this.auth.force) this.actionList[7].banned = true;

        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
        this.getmerchantArr()
    }
}