<template>
    <!-- 公众号提醒 -->
    <el-dialog :visible="viewIsReady"
        @close="handleClose"
        title="获取订单地址"
        width="600px"
        class="link-pop"
    >

        <p>{{ link }}</p>
        <el-button @click.stop="handleCopyBtn"
            class="copy-btn" v-dbclick>复制</el-button>

        <div slot="footer">
            <el-button
                @click="handleClose"
                type="primary"
            >关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'WxLink',
    props: ["order"],

    data () {
        return {
            viewIsReady: false,
            link: "",
        }
    },

    watch: {
        order (order, old) {
            if (order && order !== old) {
                this.viewIsReady = true;
                this.$nextTick().then(this.fetchData)
            }
            else this.viewIsReady = false;
        }
    },

    methods: {
        async fetchData () {
            let loadingRef = this.$loading({
                target: ".link-pop .el-dialog"
            });

            try {
                const res = await this.$axios({
                    url: "api/order/getWeiXinUrlLink",
                    params: {orderId: this.order.id}
                })
                if (res.code === 2000) {
                    this.link = res.data.urlLink;
                }
                else if (res.code !== 1003) throw(res)

            } catch (reason) {
                this.$message({
                    message: reason.msg || "获取链接失败",
                    type: "warning"
                })
                console.warn("获取链接失败", reason);
                this.handleClose();
            }

            loadingRef.close()
        },

        // 复制
        handleCopyBtn () {
            const fine = this.link.pushToClipBoard();
            if (fine) this.$message({
                message: "复制成功",
                type: "success"
            });
            else this.$message({
                message: "复制失败, 请手动复制",
                type: "warning"
            })
        },

        resetData () {
            this.link = "";
        },

        handleClose () {
            this.resetData();
            this.$emit("update:order", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .copy-btn {
        margin-top: 1em;
    }
</style>