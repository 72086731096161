<!-- 编辑收货信息 -->
<template>
    <el-dialog :visible="!!show" title="修改收货地址"
            @close="handleIssueClose" width="600px" class="batch-express">

        <el-form label-suffix=": "
            label-width="150px"
            class="private" ref="form"
            :model="PB"
            :rules="rules"
            @submit.prevent="handleBulkIssue">

            <el-form-item prop="receiver" label="收货人姓名">
                <el-input v-model="PB.receiver" maxlength="20"
                    class="width2"></el-input>
            </el-form-item>
            <el-form-item prop="showPhone" label="联系电话">
                <el-input v-model="PB.showPhone" maxlength="20"
                    class="width2" type="text"></el-input>
            </el-form-item>
            <el-form-item label="收货人地址" required>
                <div>
                    <el-select v-model="PB.province" class="width1" @change="e => handleRegionSelect(e, 1)">
                        <el-option v-for="p in regionList1"
                            :value="p.name"
                            :key="p.id"
                            :label="p.name"
                        ></el-option>
                    </el-select>
                    <el-select v-model="PB.city" class="width1" @change="e => handleRegionSelect(e, 2)">
                        <el-option v-for="p in regionList2"
                            :value="p.name"
                            :key="p.id"
                            :label="p.name"
                        ></el-option>
                    </el-select>
                    <el-select v-model="PB.area" class="width1">
                        <el-option v-for="p in regionList3"
                            :value="p.name"
                            :key="p.id"
                            :label="p.name"
                        ></el-option>
                    </el-select>
                    <el-input v-model="PB.address" maxlength="100"
                        type="textarea" class="address-input"></el-input>
                </div>
            </el-form-item>
            <el-form-item label="身份证信息" prop="img" class="wrap">
                <el-upload
                    class="upload-row"
                    :action="$baseURL + 'api/common/fileSingleUpLoad'"
                    :headers="uploadHeaders"
                    :limit="2"
                    :file-list="PB.img"
                    :show-file-list="false"
                    :on-success="handleUploadSuccess"
                    :on-exceed="handleExceed"
                    :before-upload="beforeFileUpload"
                    :on-progress="handleProgress"
                >
                    <el-button size="small">点击上传</el-button>
                </el-upload>

                <thumbnail-list :images="PB.img" :show-delete="true"
                    @delete="handleIconDelete"></thumbnail-list>
            </el-form-item>
            <!-- <el-form-item label="上传订单物流号" prop="cusfile" required class="issue-upload-feild">
                <el-upload
                    class="upload-row"
                    :action="$baseURL + 'api/common/fileSingleUpLoad'"
                    :auto-upload="false"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :data="{test: 1}"
                    :limit="1"
                    :file-list="issueData.cusfile"
                    :on-exceed="handleExceed"
                    :on-change="handleSelectFile"
                    :on-remove="handleRemoveFile"
                >
                    <el-button size="small">选择文件</el-button>
                </el-upload>
                <a download target="_blank" :href="sampleFileUrl">下载模板</a>
            </el-form-item> -->
        </el-form>

        <!-- <div v-else class="result"> 上传成功！</div> -->

        <div slot="footer">
            <el-button @click="handleIssueClose">返回</el-button>
            <el-button @click="handleBulkIssue" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { Loading } from 'element-ui';
export default {
    name: 'UpdateAddress',
    props: {
        address: {
            type: Object,
            default () {
                return {}
            }
        },
        show: Boolean
    },
    data () {
        return {
            PB: {
                orderId : "", // 357
                receiver: "", // 白菜1111
                showPhone   : "", // 15828254251
                province: "", // 四川
                city    : "", // 成都
                area    : "", // 锦江区
                address : "", // 11111
                img: [],       // 图片
            },
            rules: {
                receiver: { required: true, message: "请输入收货人姓名" },
                showPhone: { required: true, message: "请输入收货人联系电话" }
            },

            regionList1: [],
            regionList2: [],
            regionList3: [],
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            },
            posting:false,
        }
    },
    watch: {
        address (value) {
            if (value) {
                for (let key in this.PB) {
                    this.PB[key] = value[key]
                }

                this.fetchGegion()
            }
        }
    },
    
    methods: {
        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.posting = false;
            const index = this.PB.img.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.img, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("img")
            } else {
                this.PB.img.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },
        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传两张图片",
            type: "error",
          });
        },
        beforeFileUpload(file) {
            if(this.posting){
                this.$message({
                    message: "请等待图片上传完成",
                    type: "warning"
                });
                return false
            }
            // 上传图片之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
        
            if (!isImage) {
                this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                // this.userInterface.uploadIconProcess = true;
                // file.progress = 0;
                console.log(this);
                this.PB.img.push(file)
                return true;
            }
            

            return false;
        },
        handleProgress(e, file) {
            this.posting = true;
            const index = this.PB.img.indexOf(file);
            this.$set(this.PB.img, index, file)
        },
        // 删除文件
        handleIconDelete (index) {
            this.PB.img.splice(index, 1);
        },

        handleIssueClose () {
            this.$emit("update:show", false);
        },

        fetchGegion (preid=0, cls=1) {
            const clsName = ["province", "city", "area"][cls-1];
            return this.$axios({
                url: "api/order/areaCity",
                params: { preid }
            })
            .then(res => {
                if (res.code === 2000) {
                    const {list} = res.data;
                    this[`regionList${cls}`] = list;
                    return list;

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .then(supList => {
                const supText = this.PB[clsName];
                if (cls < 3 && supText) {
                    const supItem = supList.find(it => it.name === supText);
                    if (supItem?.id) {
                        cls++;
                        return this.fetchGegion(supItem.id, cls)
                    }
                }
            })
            .catch(reason => console.log("获取地区数据失败", reason))
        },

        handleBulkIssue () {
            if (this.posting) return this.$message({
                message: "请等待上传完成",
                type: "warning"
            });
            let loadingRef;
            this.$refs.form.validate()
            .then(res => {
                const {province, city, area, address} = this.PB;
                if (!province) return Promise.reject("请选择省份")
                if (!city) return Promise.reject("请选择城市")

                if (!(address.trim().length)) return Promise.reject("请填写收货地址")

            })
            .then(res=> {
                loadingRef = Loading.service()
                return this.$axios({
                    url: "api/order/updateOrderAddress",
                    method: "post",
                    data: {
                        ...this.PB,
                        phone:this.PB.showPhone,
                        idImages1:this.PB.img.length>0? this.PB.img[0].path:'',
                        idImages2:this.PB.img.length>1?this.PB.img[1].path:'',
                        address: this.PB.address.trim()
                    }
                })
            })
            .then(res => {
                if (loadingRef) loadingRef.close();
                if (res.code === 2000) {
                    this.$emit("change", {
                        ...this.PB,
                        address: this.PB.address.trim()
                    });
                    this.$message({
                        message: "修改成功", 
                        type: "success"
                    })
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                if (reason !== false) { // false 是验证没通过
                    if (loadingRef) loadingRef.close();
                    console.log("修改失败", reason);
                    if (typeof reason === "object") reason = reason.msg;
                    this.$message({
                        message: reason || "修改失败",
                        type: "error"
                    })
                }
            })
        },

        handleRegionSelect(value, cls) {
            const keys = ["province", "city", "area"];
            for (let i=cls+1; i<=3; i++) {
                this.PB[keys[i-1]] = "";
                this[`regionList${i}`] = []
            }
            const item = this[`regionList${cls}`].find(it => it.name === value)
            this.fetchGegion(item.id, cls+1)
        }
    }
}
</script>

<style lang='scss' scoped>
    .address-input {
        margin-top: 10px;
    }
</style>