import { Loading } from 'element-ui';
import forEdit from "@/mixin/forEdit";
import forList from "@/mixin/forList";
import UpdateAddress from "./UpdateAddress.vue";
import UpdateRemark  from "./UpdateRemark.vue";
import Phones from "@/components/Phones.vue";


export default {
    name: 'OrderDetail',
    mixins: [forEdit, forList],
    noSaveState: true,
    components: {
        "update-address": UpdateAddress,
        "update-remark": UpdateRemark,
        Phones
    },

    data () {
        return {
            id: 0,            // 订单id
            orderNo: '',
            profile: {},      // 基本信息
            goodsList: [],    // 订单商品列表
            stateMap: {
                "-1": "已关闭（后台）",
                "0":  "已取消",
                "1":  "待支付" ,
                "2":  "已支付" ,
                "4":  "已发货",
                "7":  "已退单",
                "10": "已完成",
            },

            // post body 请求体数据
            rules: {
                money: {
                    required: true,
                    message: "请输入充值数量",
                    trigger: "blur"
                }
            },
            actionList: [
                {
                    label: "编辑服务明细",
                    name: "detail"
                }
            ],
            value: "00:00",
            voucherInfo: null,        // 显示的优惠券信息
            editingINdex: 0,          // 正在编辑的商品序号
            editingBookingInfo: null, // 正在编辑的商品的预约信息
            timePickerOptions: {
                // selectableRange: '18:30:00 - 20:30:00',
                format: "HH:mm"
            },
            uploadHeaders: {          // 图片上传的请求头
                token: this.$store.state.token
            },
            uploading: false,         // 上传中
            auth: {
                edit: false,          // 编辑权限
                readIdCard: false,    // 查看身份证大图
            },

            showEditingAddress: false, // 开启收货地址编辑窗
            editingAddress: {},        // 传入的收货地址信息
            updatingRemark: null,
            activeName: 'first',
            phoneList: [],
            billDetailList: [],
        }
    },

    computed: {
        // hasMailGoods () {
        //     return this.goodsList.some(goods => goods.type === 1)
        // }
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/order/queryAddressPhone",
                params: {
                    orderId: row.order_id
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data) {
                            if (res.data[key]) {
                                _phoneList.push(res.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },


        fetchData() {
            console.log(this.$route.params.tab);
            console.log(this.tab);
            if (this.loading) return;
            this.loading = true;
            // 获取订单详情
            let data = ''
            if (this.$route.params.tab) {
                data = { orderNo: this.$route.params.tab }
            }
            if (this.id) {
                data = { orderId: this.id }
            }

            this.$axios("api/order/queryOrderInfo", {
                params: data
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.getListByOrderNo(res.data.orderAndAddrMap.order_no)
                    const {orderAndAddrMap, orderGoodsList, isOrderIdcard} = res.data;

                    // 解析订单数据：图片数据
                    orderAndAddrMap.idCardImages = [
                        orderAndAddrMap.id_images_1,
                        orderAndAddrMap.id_images_2,
                    ].filter(path => !!path).map(it => // 过滤 null
                        this.$baseURL + it);           // 拼接完整url

                    // 解析订单数据：所属业务区域
                    orderAndAddrMap.areaName = orderAndAddrMap.areaNamelist.filter(area => 
                        !!area).join()

                    // 解析商品列表数据: 单个商品的小计金额、预约信息
                    orderGoodsList.forEach(goods => {
                        goods.sale_price_text = goods.sale_price + (
                            orderAndAddrMap.pay_way == 2 ? "元" : "积分"
                        );
                        goods.goods_sku = goods.goods_sku?.trim().replace(/\s/g, ",");
                        if (goods.conf_value) {
                            let conf_value = JSON.parse(goods.conf_value);
                            conf_value.forEach(field => {
                                if (field.type === 8) field.value = field.value.map(image => {
                                    image.path = this.$baseURL + image.path
                                    return image;
                                })
                            })
                            goods.conf_value = conf_value
                        }
                    })
                    // 隐藏电话号码中间四位
                    // orderAndAddrMap.showphone = ''
                    // if (orderAndAddrMap.phone) {
                    //     orderAndAddrMap.showphone = orderAndAddrMap.phone.substring(0, 3) + "****" + orderAndAddrMap.phone.substr(orderAndAddrMap.phone.length - 4)
                    // }
                    this.auth.readIdCard = !!isOrderIdcard;
                    this.profile = orderAndAddrMap;
                    this.goodsList = orderGoodsList;
                    this.loading = false;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            
            .catch(reason => {
                console.warn("获取订单信息失败", reason)
                this.$message({
                    message: reason.msg || "获取订单信息失败",
                    type: "warning"
                })
            });
        },
        // 
        getListByOrderNo(orderNo) { //获取订单积分信息
            this.$axios("api/billDetail/getListByOrderNo", {
                params: { orderNo: orderNo }
            })

                .then(res => {
                    if (res.code === 2000) {
                        this.billDetailList = res.data.billDetailList || []
                        this.billDetailList.map(item => {
                            item.money && (item.money = item.up_down == 2 ? -item.money : "+" + item.money);
                        })
                    } else if (res.code !== 1003) return Promise.reject(res)
                })

                .catch(reason => {
                    console.warn("获取订单积分信息失败", reason)
                    this.$message({
                        message: reason.msg || "获取订单积分信息失败",
                        type: "warning"
                    })
                });
        },

        handleAddressEditBtn () {
            const { receiver, phone, province, city, area, address, showPhone, id_images_1, id_images_2 } = this.profile;
            this.editingAddress = {
                orderId: this.id,
                receiver, phone, province, city, area, address, showPhone,
                img: []
            }
            if (id_images_1) {
                this.editingAddress.img.push(
                    {
                        name: id_images_1.slice(id_images_1.lastIndexOf("/") + 1),
                        url: this.$baseURL + id_images_1,
                        path: id_images_1
                    }
                )
            }
            if (id_images_2) {
                this.editingAddress.img.push(
                    {
                        name: id_images_2.slice(id_images_2.lastIndexOf("/") + 1),
                        url: this.$baseURL + id_images_2,
                        path: id_images_2
                    }
                )
            }
            this.showEditingAddress = true;
        },

        // 点击优惠金额
        handleVoucher (item) {
            this.voucherInfo = item;
        },

        // 点击编辑服务明细
        handleEdit (index) {
            const goods  = this.goodsList[index];
            this.editingINdex = index;
            // 复制一份预约信息，以便于存储编辑时候的值。待点击确定并保存后
            // 将副本中的值复制回原本。
            const copyBookInfo = JSON.parse(JSON.stringify(goods.conf_value));
            // copyBookInfo.forEach(field => {
            //     if (field.type === 8) {
            //         console.log("图片域", field, field.value, field.value.constructor);
            //         let value;
            //         if (field.value.constructor === Array) value = field.value;
            //         else if (!field.value) value = [];
            //         else value = field.value.split(",");
            //         value = value.map(path => {
            //             return {
            //                 name: path.slice(path.indexOf("/") + 1),
            //                 url: this.$baseURL + path,
            //                 path: path
            //             }
            //         });
            //         field.value = value;
            //     }
            // })

            this.$set(this, "editingBookingInfo", copyBookInfo)
        },

        // 点击服务明细对话框的取消
        handleEditCancel () {
            this.editingBookingInfo = null;
        },
        
        // 点击服务明细对话框的确定
        handleEditConfirm () {
            const loadingInstance = Loading.service({
                target: document.querySelector(".el-dialog")
            })
            const {editingINdex: index, editingBookingInfo: bookingInfo, goodsList} = this;
            const goods = goodsList[index];
            const serviceStr = bookingInfo.map(item => {
                if (item.type === 8) {
                    item.value = item.value.map(file => {
                        file.path = file.path.replace(this.$baseURL, "");
                        return file;
                    })
                }
                return item
            })
            const data = {
                orderGoodsId: goods.id,
                serviceStr: JSON.stringify(serviceStr)
            }
            // return console.log(data);
            return this.$axios({
                url: "api/order/updateOrderService",
                method: "post",
                data
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "修改成功",
                        type: "success"
                    });
                    loadingInstance.close();
                    this.handleEditCancel();
                    this.fetchData();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                loadingInstance.close();
                this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn("修改预约服务明细失败", reason);
            })
        },

        handleCheck (row) {
            const $table = this.$refs.goodTable
            $table.toggleRowExpansion(row)
        },

        // 点击展开按钮时: 检查有无展开内容，没有就折叠
        handleExpandChange(row) {
            const $table = this.$refs.goodTable
            if (!row.conf_value) $table.toggleRowExpansion(row, false)
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传五张图片",
            type: "error",
          });
        },

        beforeFileUpload(file, index) {
          // 上传图标之前，判断图标格式
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            // this.userInterface.uploadIconProcess = true;
            this.editingBookingInfo[index].value.push(file)
            return true;
          }
          return false;
        },
        
        handleProgress(e, file, index) {
            this.uploading = true;
            const i = this.editingBookingInfo[index].value.indexOf(file);
            // console.log(index);
            this.$set(this.editingBookingInfo[index].value, i, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file, index) {
            this.uploading = false;
            // if (res.code === 2000) {
            //     const {oldName, filePath} = res.data.files;
            //     console.log(oldName, filePath);
            //     this.PB.pictures.push({
            //         name: oldName,
            //         path: filePath,
            //         url: this.$baseURL + filePath,
            //     })
            // }
            const i = this.editingBookingInfo[index].value.indexOf(file);
            if (res.code === 2000) {
                // console.log(file);
                // console.log(res, file)
                // const {oldName, filePath} = res.data.files;
                // this.PB.pictures.push({
                //     name: oldName,
                //     url: this.$baseURL + filePath,
                //     path: filePath
                // })

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                // console.log(index);
                this.$set(this.editingBookingInfo[index].value, i, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                // this.$refs.form.clearValidate("img")
            } else {
                this.editingBookingInfo[index].value.splice(i, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (i, index) {
            this.editingBookingInfo[index].value.splice(i, 1);
        },

        // 点击编辑订单备注
        handleRemarkUpdateBtn () {
            console.log("---",this.profile);
            this.updatingRemark = this.profile
        },

        // 点击返回按钮
        handleNavigateBackBtn () {
            this.$router.back();
        },

        handleAddressChange (e) {
            for (let key of ["receiver", "phone", "province", "city", "area", "address"]) {
                this.profile[key] = e[key];
            }
            this.fetchData();
            this.showEditingAddress = false;
        },

        handleRemarkChange (e) {
            console.log("gaibi", e);
            this.fetchData();
        }
    },

    created () {
		this.auth.edit = this.$hasAuthFor("api/order/updateOrderService");
		this.auth.address = this.$hasAuthFor("api/order/updateOrderAddress");
		this.auth.content = this.$hasAuthFor("api/order/updateContent");
        this.auth.seePhone = this.$hasAuthFor("api/order/queryAddressPhone");
    },
}