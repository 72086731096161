<template>
    <!-- 留学签证信息确认编辑窗 -->
    <el-dialog :visible="viewIsReady"
        @close="handleClose"
        title="留学签证信息确认"
        width="800px">

        <ul class="brief">
            <li class="brief-item">
                <span>商品名称</span>
                <span>{{ originData.goods_name }}</span>
            </li>

            <li class="brief-item">
                <span>办理项目</span>
                <span>{{ originData.contractName }}</span>
            </li>

            <!-- <li class="brief-item">
                <span>规格</span>
                <span>{{ originData.goods_sku }}</span>
            </li> -->

            <!-- <li class="brief-item">
                <span>类别</span>
                <span>{{ selectedKind.name }}</span>
            </li>

            <li class="brief-item">
                <span>年限</span>
                <span>{{ selectedYear.name }}</span>
            </li> -->

            <li class="brief-item">
                <span>客户编号</span>
                <span>{{ originData.cust_code }}</span>
            </li>

            <li class="brief-item">
                <span>客户区域</span>
                <span>{{ originData.areaName }}</span>
            </li>

            <li class="brief-item">
                <span>客户姓名</span>
                <span>{{ originData.cust_name }}</span>
            </li>

            <li class="brief-item">
                <span>电话号码</span>
                <span>{{ originData.cust_phone }}</span>
            </li>
        </ul>
        <el-form class="private"
            label-width="120px"
            label-position="right"
            label-suffix=":"
            :model="PB"
            ref="form"
        >

            <!-- <el-form-item label="类别" prop="visaType">
                <el-select v-model="PB.visaType">
                    <el-option
                        v-for="opt in visaTypeList"
                        :key="opt.id"
                        :value="opt.id"
                        :label="opt.name"
                    ></el-option>
                </el-select>
            </el-form-item> -->

            <!-- <el-form-item label="年限" prop="deadline">
                <el-select v-model="PB.deadline">
                    <el-option
                        v-for="opt in deadLineList"
                        :key="opt.id"
                        :value="opt.id"
                        :label="opt.name"
                    ></el-option>
                </el-select>
            </el-form-item> -->

            <el-form-item v-for="(field, fieldindex) in PB.confValue"
                :key="field.name" :label="field.name" class="wrap">

                <!-- 文本框 -->
                <el-input v-if="field.type == 1"
                    class="width2"
                    v-model.trim="field.value"></el-input>

                <!-- 单选 -->
                <el-radio-group v-if="field.type == 2"
                    class="width2"
                    v-model="field.value">
                    <el-radio v-for="it in field.options"
                        :key="it" :label="it"></el-radio>
                </el-radio-group>

                <!-- 复选 -->
                <el-checkbox-group v-if="field.type == 3"
                    class="width2"
                    v-model="field.value">
                    <el-checkbox v-for="it in field.options"
                        :key="it" :label="it"></el-checkbox>
                </el-checkbox-group>

                <!-- 富文本 -->
                <el-input v-if="field.type == 4" v-model="field.value"
                    class="width2"
                    type="textarea" rows="4"></el-input>

                <!-- 日期 -->
                <el-date-picker v-if="field.type == 5"
                    class="width2"
                    v-model="field.value" value-format="yyyy-MM-dd"
                    placeholder="YYYY-MM-DD"></el-date-picker>

                <!-- 时间 -->
                <el-time-picker v-if="field.type == 6"
                    class="width2"
                    v-model="field.value"
                    value-format="HH:mm"
                    :picker-options="timePickerOptions"
                    placeholder="hh:ss"></el-time-picker>

                <!-- 日期时间 -->
                <el-date-picker v-if="field.type == 7"
                    class="width2"
                    v-model="field.value"
                    type="datetime" placeholder="YYYY-MM-DD hh:ss"
                    value-format="yyyy-MM-dd hh:ss"></el-date-picker>

                <!-- 上传 -->
                <template v-if="field.type == 8">
                    <el-upload
                        class="upload-demo"
                        :action="$baseURL + 'api/common/fileSingleUpLoad'"
                        :headers="uploadHeaders"
                        :limit="5"
                        :on-success="(res, file) => handleUploadSuccess(res, file, fieldindex)"
                        :file-list="field.value"
                        :show-file-list="false"
                        :on-exceed="handleExceed"
                        :before-upload="file => beforeFileUpload(file, fieldindex)"
                        :on-progress="(e, file) => handleProgress(e, file, fieldindex)"
                    >
                        <el-button size="small" type="primary">点击上传</el-button>
                        <!-- <div slot="tip" class="el-upload__tip">最多上传{{ field.optionValue }}张图片</div> -->
                    </el-upload>

                    <thumbnail-list :images="field.value" :show-delete="true"
                        @delete="index => handleIconDelete(index, fieldindex)"></thumbnail-list>
                </template>

            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose" :disabled="false"
                >关闭</el-button>

            <el-button v-dbclick @click="handleSave" type="primary"
                >确定转文案中心</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { Loading } from 'element-ui';
export default {
    name: 'TurnToYimin',
    props: ["order"],

    data () {
        return {
            viewIsReady: false,
            PB: {
                proId   : "", // 类别id
                // deadline   : "", // 年限id
                confValue: [],
                orderGoodsId: "",
                // outconfigId: ""
            },

            originData  : {},
            timePickerOptions: {
                format: "HH:mm"
            },
            uploading: false
        }
    },
    watch: {
        order (order, old) {
            if (order && order !== old) {
                this.originData = order;
                this.PB.orderGoodsId = order.id;
                this.PB.proId  = order.outconfigId;
                // 初始化confValue
                if (order.conf_value) {
                    const confValue      = JSON.parse(JSON.stringify(order.conf_value));
                    confValue.forEach(field => {
                        if (field.type === 8) field.value = field.value.map(image => {
                            image.path = this.$baseURL + image.path
                            return image;
                        })
                    })
                    this.PB.confValue = confValue;
                }

                this.fetchData();
                this.viewIsReady    = true;

            } else {
                this.originData   = {}
            }
        }
    },

    methods: {
        fetchData(obj){
   
            this.$axios({
                    url: "api/config/queryAymContractList",
                    method: "post",
                    data: {
                       
                    }
                }).then(res => {
                if (res.code === 2000) {
                    let contractAll=res.data.contractAll;
                    
                    contractAll.forEach(field => {
                        if (field.id === this.originData.outconfigId){
                            this.originData.contractName=field.name;
                            console.log( this.originData.contractName)
                            return;
                        }
                   
                    })
                   

                }
            })
            .catch(reason => {
                console.warn("获取签约失败", reason);
                this.$message({
                    message: reason.msg || "获取签约数据失败",
                    type: "warning"
                });
            })     
           
        },
        handleSave () {
            let loadingRef;
            this.$refs.form.validate()
            .then(() => {
                loadingRef = Loading.service({
                    target: ".express-pop .el-dialog"
                });
                return this.$axios({
                    // url: "api/order/sendYMOrder",
                    url: "api/order/sendStudyOrder",
                    method: "post",
                    data: {
                        ...this.PB,
                        confValue: JSON.stringify(this.PB.confValue)
                    }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change", {
                        // goods_sku: [this.selectedKind, this.selectedYear].map(it =>
                        //     it.name).join(" "),
                        conf_value: this.PB.confValue
                    })
                    this.handleClose();
                    // this.fetchData();

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            })
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB.proId     = "", // 类别id
            // this.PB.deadline     = "", // 年限id
            this.PB.confValue    = [],
            this.PB.orderGoodsId = "",
            // this.PB.outconfigId  = ""
            this.$refs.form.clearValidate();
            this.$emit("update:order", null);
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传五张图片",
            type: "error",
          });
        },

        beforeFileUpload(file, index) {
          // 上传图标之前，判断图标格式
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            this.PB.confValue[index].value.push(file)
            return true;
          }
          return false;
        },
        
        handleProgress(e, file, index) {
            this.uploading = true;
            const i = this.PB.confValue[index].value.indexOf(file);
            this.$set(this.PB.confValue[index].value, i, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file, index) {
            this.uploading = false;
            const i = this.PB.confValue[index].value.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.confValue[index].value, i, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
            } else {
                this.PB.confValue[index].value.splice(i, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (i, index) {
            this.PB.confValue[index].value.splice(i, 1);
        },
    }
}
</script>

<style lang='scss' scoped>
    .brief {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }
    .brief-item {
        width: 48%;
        display: flex;

        span:first-child {
            width: 108px;
            padding-right: 12px;
            color: gray;
            text-align: right;
            
            &::after{
                content: ": ";
            }
            &:last-child {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(n+3) {
            margin-top: 12px;
        }
    }
    .el-form {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .el-form-item {
        width: 48%;
        .full-width {
            width: 100%
        }
    }
    
</style>